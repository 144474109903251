// Bootstrap
import jQuery from "jquery";
import "bootstrap";

// NPM dependencies
import "jquery-colorbox";
import "slick-carousel";
import "smartmenus";
// import "superfish";

import "./styles/main.scss";

import "./images/logo.svg";
import "./images/placeholder.svg";
import "./images/placeholder-square.svg";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  const Sage = {
    // All pages
    common: {
      init() {
        // JavaScript to be fired on all pages
        $("#menu-primary").smartmenus();
      },
      finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        const wpGallery = {
          rel: "wp-gallery",
          maxWidth: "80%",
          maxHeight: "80%",
          title() {
            return $(this).find("img").attr("alt");
          },
        };
        $(".gallery-item a").colorbox(wpGallery);
      },
    },
    // Home page
    home: {
      init() {
        // JavaScript to be fired on the home page
        $(".vv-slider").slick({
          arrows: true,
          dots: true,
          autoplay: true,
          prevArrow:
            '<div class="vv-slider-prev"><i class="fa fa-chevron-circle-left"></div>',
          nextArrow:
            '<div class="vv-slider-next"><i class="fa fa-chevron-circle-right"></div>',
        });
      },
      finalize() {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    single_plant: {
      init() {
        $(".vv-plant-slider").slick({
          arrows: false,
          asNavFor: ".vv-plant-slider__nav",
          autoplay: true,
          dots: false,
          fade: true,
          lazyLoad: "ondemand",
          slidesToScroll: 1,
          slidesToShow: 1,
        });
        $(".vv-plant-slider__nav").slick({
          arrows: false,
          asNavFor: ".vv-plant-slider",
          autoplay: true,
          centerMode: true,
          dots: false,
          focusOnSelect: true,
          lazyLoad: "progressive",
          slidesToScroll: 1,
          slidesToShow: 3,
        });

        $(".vv-plant__img").colorbox({ rel: "plantGallery" });
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init() {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  const UTIL = {
    fire(func, funcname, args) {
      let fire;
      const namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
